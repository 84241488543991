/* first modal */
.modal_emoji_container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding-bottom: 1rem;
}

.modal_emoji_container img {
  width: 50px;
  cursor: pointer;
}
.modal_emoji_container img:hover {
  transform: scale(1.25);
}
.modal_emoji_container img {
  transition: transform 0.15s;
}

/* modal 2 */
.second_modal p {
  text-align: center;
  margin: 0.5rem;
}
